<template>
  <div class="verify">
    <div class="verify__box">
      <h1>
        Weryfikacja<br />
        <span style="font-weight:bold">numeru telefonu</span>
      </h1>
      <a-divider />
      <p style="margin-bottom:0;">
        Podaj kod weryfikacyjny, który wysłaliśmy na numer
        <span style="font-weight:bold">{{ phoneNumber }}</span
        >.
      </p>
      <!-- <a-button type="link">
        Zmień numer telefonu
      </a-button> -->
      <a-form
        class="verify__form"
        ref="refForm"
        :model="formModel"
        :rules="formRules"
        @submit="verify"
      >
        <a-form-item name="verificationCode">
          <a-input
            autocomplete="off"
            style="margin-top:32px;margin-bottom:8px;"
            v-model:value="formModel.verificationCode"
            placeholder="Kod weryfikacyjny"
          />
        </a-form-item>

        <div class="verify__send-again">
          <span>Nie otrzymałeś kodu? </span>
          <a-button :loading="sendingSMS" @click="sendSMS" type="link">
            Wyślij ponownie
          </a-button>
        </div>

        <a-button
          class="verify__submit"
          type="primary"
          :loading="processingToTheNextPage"
          html-type="submit"
          :disabled="!valid"
        >
          Zweryfikuj numer telefonu
        </a-button>
      </a-form>
    </div>
  </div>
</template>

<script>
import { store, actions } from "@/store";
export default {
  name: "verify",
  props: ["phoneNumber", "pesel", "redirectedFrom"],
  data() {
    return {
      processingToTheNextPage: false,
      sendingSMS: false,
      valid: false,
      formModel: {
        verificationCode: "",
      },
      formRules: {
        verificationCode: [
          {
            required: true,
            message: "Wpisz kod weryfikacyjny",
          },
        ],
      },
      store,
    };
  },
  watch: {
    formModel: {
      async handler() {
        this.$refs.refForm
          .validate()
          .then(() => {
            this.valid = true;
          })
          .catch(() => {
            this.valid = false;
          });
      },
      deep: true,
    },
  },
  methods: {
    sendSMS() {
      this.sendingSMS = true;
      this.$http
        .post("patient-portal/api/sms-login", {
          phoneNumber: this.phoneNumber,
          pesel: this.pesel,
        })
        .then(() => {
          this.sendingSMS = false;
          this.$notification.open({
            message: "SMS został wysłany pomyślnie",
            class: "success",
          });
        })
        .catch(() => {
          this.sendingSMS = false;
          this.$notification.open({
            message: "Wystąpił błąd podczas wysyłania SMS-a",
            class: "error",
          });
        });
    },
    verify() {
      this.processingToTheNextPage = true;
      this.$http
        .post("patient-portal/api/sms-login/verify", {
          pesel: this.pesel,
          phoneNumber: this.phoneNumber,
          verificationCode: this.formModel.verificationCode,
        })
        .then((res) => {
          this.processingToTheNextPage = false;

          const user = JSON.parse(atob(res.data.access_token.split(".")[1]));

          this.$http.defaults.headers.common = {
            Authorization: `Bearer ${res.data.access_token}`,
          };

          this.$http.get("patient-portal/api/agreements").then((res2) => {
            actions.setUser({
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
              expiresOn:
                new Date().getTime() + res.data.refresh_expires_in * 1000,
              expiresTimeout: res.data.refresh_expires_in * 1000,
              roles: user.realm_access.roles,
              agreements: res2.data,
            });
            this.$session().init();
            this.$router.push(this.redirectedFrom || "/");
          });
        })
        .catch(() => {
          this.processingToTheNextPage = false;
          this.$notification.open({
            message: "Wystąpił błąd podczas weryfikacji",
            class: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$verify-box-width: 450px;

.verify {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: inherit;
  .verify__box {
    max-width: $verify-box-width;
    background-color: white;
    padding: 40px 80px 40px 80px;
    margin: 15vh 0;

    @media (max-width: $verify-box-width) {
      padding: 10px 16px 24px 16px;
      min-height: inherit;
      margin: 0;
    }

    > h1 {
      color: $c1;
      line-height: 1.4em;
      margin: 0;
      font-size: 32px;
    }

    .ant-divider-horizontal {
      margin-top: 11px;
    }

    .verify__send-again {
      font-size: 12px;
      .ant-btn-link {
        font-size: 12px;
      }
    }

    .verify__submit {
      margin-top: 80px;
      height: 40px;
    }
  }
}
</style>
